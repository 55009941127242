import React, { Component } from 'react';
import './App.css';
import { GoogleLogin } from 'react-google-login';
import Form from './components/form';
import Sky from './components/sky';


class App extends Component {

    constructor() {
        super();
        this.state = { isAuthenticated: false, user: null, token: '', domain2: ''};
    }

    logout = () => {
        this.setState({isAuthenticated: false, token: '', user: null, domain2: ''})
    };

    onFailure = (error) => {
        alert(error);
    };

    googleResponse = (response) => {
        const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',
            cache: 'default'
        };
        fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/google`, options).then(r => {
            const token = r.headers.get('x-auth-token');
            r.json().then(user => {
                if (token) {
                    // Domains that are authorized and setup to use GSS
                    const authDomains = [
                        'hello9dot.com',
                        '9dot-es.com',
                        'alltechsi.com',
                        'alltechent.com',
                        'edudyn.com',
                        'hawkeyeproperties.org',
                        'lupineproperties.com',
                        'oflschools.org',
                        'ofy.org',
                        'partnersped.com',
                        'pathwaysmg.org',
                        'prepforsuccess.org',
                        'skyrocket-ed.com'
                    ]
                    const address = user.email.split('@').pop()
                    if (authDomains.includes(address)) {
                        this.setState({isAuthenticated: true, user, token, address})                       
                    }
                    else {
                        // {this.onFailure}
                        alert(`Your Domain "${address}" is not Authorized`);
                    }
                    // this.setState({isAuthenticated: true, user, token})

                }
            });
        })
    };

    render() {
    let content = !!this.state.isAuthenticated ?
            (
                this.state.address === 'skyrocket-ed.com' || this.state.address === 'alltechent.com' ? 
                (
                    <div>
                        <Sky ginfo={this.state}/>
                    </div>
                ):
                (
                    <div>
                        <Form ginfo={this.state}/>
                    </div>
                )
            ) :
            (
                <div className="App">
                    <div className="container">
                        <div className="py-5 text-center">
                            <h2>Email Signature Generator</h2>
                            <br />
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                buttonText="Login"
                                scope= "profile"
                                onSuccess={this.googleResponse}
                                onFailure={this.onFailure}
                            />
                        </div>
                    </div>
                </div>
            );

        return (
            <div className="App">
                {content}
            </div>
        );
    }
}

export default App;
