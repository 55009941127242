import React from 'react';

class Preview extends React.Component {
	// eslint-disable-next-line
    constructor(props){
        super(props);
    }
    render() {
		const domain = this.props.gi.user.email.substring(this.props.gi.user.email.lastIndexOf("@") +1);
		const compName = domain.slice(0, -4);
		// let tnum = this.props.information.wnum;
		// let fnum = this.props.information.fnum;
		let compColor;

		// if (tnum) {
		// 	this.props.information.tnum = tnum.slice(0,3) + '.' + tnum.slice(3,6) + '.' + tnum.slice(6,10);
		// }
		// if (fnum) {
		// 	this.props.information.fnums = fnum.slice(0,3) + '.' + fnum.slice(3,6) + '.' + fnum.slice(6,10);
		// }
		console.log(this.props.information.logo);
		const logoUrl = `https://arf.alltechsi.com/images/logos/${compName}.png`
		const logoImgUrl = <img src={logoUrl} alt='' />;

		switch(compName){
			case "9dot-es":
				compColor = 'rgb(234, 11, 140)';
				break;
			case "alltechsi":
				compColor = 'rgb(27, 71, 125)';
				break;
			case "edudyn":
				compColor = 'rgb(198, 33, 39)';
				break;
			case "hawkeyeproperties":
				compColor = 'rgb(0, 0, 0)';
				break;
			case "lupine":
				compColor = 'rgb(114, 184, 76)';
				break;
			case "oflschools":
				compColor = 'rgb(244, 115, 33)';
				break;
			case "ofy":
				compColor = 'rgb(14, 135, 162)';
				break;
			case "partnersped":
				compColor = 'rgb(0, 170, 82)';
				break;
			case "pathwaycollege":
				compColor = 'rgb(28, 158, 168)';
				break;
			case "pathwaysmg":
				compColor = 'rgb(131, 183, 46)';
				break;
			case "pathwaysedu":
				compColor = 'rgb(15, 166, 21)';
				break;
			case "prepforsuccess":
				compColor = 'rgb(0, 116, 116)';
				break;
			default:
				compColor = 'rgb(0, 0, 0)';
		}
		this.props.information.color = compColor;

        return (
			<div>
				<link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet" />
				<table className="card_d" style={{margin: 'auto', padding: '5px', width: 'max-conten', fontFamily: '"Lato", sans-serif'}}>
				<tbody>
					<tr>
					<td style={{paddingRight: '5px'}}>{ logoImgUrl }</td>
					<td>
						<div style={{borderLeft: `4px solid ${compColor}`}}>
						<span style={{marginLeft: '5px'}}>
							<span style={{fontSize: 'x-large', fontWeight: 'bold', color: 'black'}}>
							{`${this.props.information.fname ? this.props.information.fname : 'John'} ${this.props.information.lname ? this.props.information.lname : 'Doe'}`}
							</span>
						</span>
						<br />
						<span style={{marginLeft: '5px'}}>
							<span style={{fontSize: 'medium', fontWeight: 'bold', color: 'black'}}>
							{this.props.information.title ? this.props.information.title : 'SysAdmin'}
							</span>
						</span>
						<br />
						<span style={{fontSize: 'small', fontWeight: 'bold', color: 'black', marginLeft: '5px'}}>
						{this.props.information.fnum ? this.props.information.fnum : '626.321.7654'}
							<span style={{borderLeft: `3px solid ${compColor}`, margin: '0px 3px', display: `${this.props.information.address ? '' : 'none'}`}}>
							</span>
							{this.props.information.address ? 
							this.props.information.address :
							 ''}
						</span>
						</div>
					</td>
					<td align="center" valign="top" rowSpan={3} />
					</tr>
				</tbody>
				</table>
			</div>
		)
    }
}

export default Preview;