import React from 'react';
import Preview from './preview'

class Form extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fname: null,
			lname: null,
			dep: null,
			pos: null,
			wnum: null,
			fnum: null,
			email: null,
			address: null,
			domain: null,
			logo: null,
			color: null
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleSubmit(event) {
		console.log(event.target);
		// var pnum;
		// if (event.target.fnum.value.length > 10) {
		// 	pnum = event.target.fnum.value.substring(0,10);
		// }
		// else {
		// 	pnum = event.target.fnum.value;
		// }
		const siginfo = {
			name: event.target.fname.value + " " + event.target.lname.value,
			title: event.target.title.value,
			domain: event.target.domain.value,
			email: event.target.email.value,
			// fnum: pnum,
			fnum: event.target.fnum.value,
			address: event.target.address.value,
			logo: event.target.logo.value,
			color: this.state.color
		}
		fetch(`${process.env.REACT_APP_API_HOST}/gs/gen`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
            body: JSON.stringify(siginfo)
		}).then((res) => {
			if (res.status !== 200) {
				alert("Something isn't right")
			}
			else {
				alert("Success")
			}			
		})
		event.preventDefault();
	}
	
	handleChange(event) {
		const id  = event.target.id;
		this.setState({[id]: event.target.value});
	}
	render() {
		const email = this.props.ginfo.user.email;
		const domain = this.props.ginfo.user.email.substring(this.props.ginfo.user.email.lastIndexOf("@") +1);
		const compName = domain.slice(0, -4);
		const logo = `https://arf.alltechsi.com/images/logos/${compName}.png`;
		// this.setState({logo: logo});
		return (
			<div className="App">
				<div className="container">
					<div className="py-5 text-center">
						<h2>Email Signature Generator </h2>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="form-row">
							<div className="col-md-6">
								<label htmlFor="fname">First Name</label>
								<input type="text" className="form-control" id="fname" placeholder="John" value={this.state.fname || ''} onChange={this.handleChange}/>
							</div>
							<div className="col-md-6">
								<label htmlFor="lname">Last Name</label>
								<input type="text" className="form-control" id="lname" placeholder="Doe" value={this.state.lname || ''}onChange={this.handleChange}/>
							</div>
						</div>
						<div className='form-group'>
							<label htmlFor="title">Title</label>
							<input type='text' className='form-control' id='title' placeholder='Teacher' value={this.state.title || ''}onChange={this.handleChange} />
						</div>
						<div className='form-group'>
							<label htmlFor="fnum">Phone Number</label>
							<input type='text' className='form-control' id='fnum' placeholder='Phone Number with/out Extension' value={this.state.fnum || ''}onChange={this.handleChange} />
						</div>
						<div className='form-group'>
							<label htmlFor="address">Address (Optional)</label>
							<input type='text' className='form-control' id='address' placeholder='Address' value={this.state.address || ''}onChange={this.handleChange} />
						</div>
						<input type='text' id='logo' value={ logo } hidden readOnly/>
						<input type='text' id='email' value={ email } hidden readOnly/>
						<input type='text' id='domain' value={ domain } hidden readOnly/>
						<button type="submit" className="btn btn-primary">Submit</button>
					</form>
                    <Preview information={this.state} gi={this.props.ginfo}/>
				</div>
			</div>
		);
	}
}

export default Form;
