import React from 'react';

class Preview extends React.Component {
	// eslint-disable-next-line
    constructor(props){
        super(props);
    }
    render() {
        return (
			<div>
				<table className="card_d" style={{margin: 'auto', padding: '5px', width: 'max-conten', fontFamily: '"Tahoma", sans-serif'}}>
				<tbody>
				<tr>
					<td>
					<div>
						<span style={{marginLeft: '5px'}}>
						<span style={{fontSize: '25px', fontWeight: 'bold', color: 'black'}}>
						{`${this.props.information.fname ? this.props.information.fname : 'John Doe'}`}
						</span>
						</span>
						<br />
						<span style={{marginLeft: '5px'}}>
						<span style={{fontSize: '20px', fontWeight: 'bold', color: 'black'}}>
						{this.props.information.title ? this.props.information.title : 'Teacher'}
						</span>
						</span>
						<br />
						<img src="https://arf.alltechsi.com/images/logos/SkyrocketNewLong.png" alt="" style={{width: '300px', height: '50px'}} />
					</div>
					</td>
				</tr>
				</tbody>
			</table>
			</div>
		)
    }
}

export default Preview;