import React from 'react';
import SkyPreview from './sky_preview'

class Form extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fname: this.props.ginfo.user.fullName,
            title: null,
            domain: null,
			logo: null,
			color: null
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleSubmit(event) {
		const siginfo = {
			name: event.target.fname.value,
			title: event.target.title.value,
			email: event.target.email.value
		}
		fetch(`${process.env.REACT_APP_API_HOST}/gs/sky`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
            body: JSON.stringify(siginfo)
		}).then((res) => {
			console.log(res)
			if (res.status !== 200) {
				alert("Something isn't right")
			}
			else {
				alert("Success")
			}			
		})
		event.preventDefault();
	}
	
	handleChange(event) {
		const id  = event.target.id;
		this.setState({[id]: event.target.value});
	}
	render() {
		const email = this.props.ginfo.user.email;
		const domain = this.props.ginfo.address;
		const compName = domain.slice(0, -4);
		const logo = `https://arf.alltechsi.com/images/logos/${compName}.png`;
		// this.setState({logo: logo});
		return (
			<div className="App">
				<div className="container">
					<div className="py-5 text-center">
						<h2>Email Signature Generator </h2>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="form-group">
                            <label htmlFor="fname">Name</label>
                            <input type="text" className="form-control" id="fname" placeholder="John Doe" value={this.state.fname || this.props.ginfo.user.fullName} onChange={this.handleChange}/>
						</div>
						<div className='form-group'>
							<label htmlFor="title">Title</label>
							<input type='text' className='form-control' id='title' placeholder='Teacher' value={this.state.title || ''}onChange={this.handleChange} />
						</div>
						<input type='text' id='email' value={ email } hidden readOnly/>
						<button type="submit" className="btn btn-primary">Submit</button>
					</form>
                    <SkyPreview information={this.state} gi={this.props.ginfo}/>
				</div>
			</div>
		);
	}
}

export default Form;
